import { useEffect, useState } from "react";
import axios from "axios";
import logo from "./logo.svg";

const App = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/v1/worker-health-status`)
      setData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  setInterval(fetchData, 30000);

  return (
    <section className="bg-white bg-cover bg-no-repeat min-h-screen" style={{ backgroundImage: 'url("wallpaper.jpg")' }}>
      <div className="max-w-7xl px-6 py-10 mx-auto">
        <img src={logo} alt="" className="w-20 h-20 mx-auto" />
        <h1 className="text-3xl font-bold lg:text-4xl text-white text-center text-gradient">SwitchWallet Workers Health Status</h1>
        {data === undefined || data.length === 0
          ? <div className="flex justify-center items-center pt-96">
            <div className="relative w-24 h-24 animate-spin rounded-full bg-gradient-to-r from-purple-400 via-blue-500 to-red-400 ">
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 bg-current rounded-full border-2 border-white" />
            </div>
          </div>
          : null
        }
        <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-16 xl:gap-12 md:grid-cols-2 xl:grid-cols-3">
          {data === undefined || data.length === 0 ? null : data.map((data, index) => (
            <div key={index} className="w-full overflow-hidden">
              <div className={`p-8 space-y-3 rounded-2xl ${data.isHealthy ? "bg-green-400" : "bg-red-400"}`}>
                <h1 className="font-semibold text-lg text-black">RAM Used {data.usedMemoryPercent} %</h1>
                <h1 className="font-semibold text-lg text-black">CPU Used {data.usedCPUPercent} %</h1>
                <h1 className="font-semibold text-lg text-black">Storage Used {data.usedStoragePercent} %</h1>
                <h1 className="font-semibold text-lg text-black">Network Speed {data.internetSpeedInKB > 0 ? (data.internetSpeedInKB / 1024).toFixed(2) : '0'} MB/s</h1>
                <div className="flex justify-end">
                  <p className="font-semibold text-base mt-4 text-black xl:mt-6">{data.isHealthy ? "Healthy" : "Unhealthy"}</p>
                </div>
              </div>
              <h1 className="text-white font-medium text-sm md:text-lg mt-5 text-center">{data.workerName}</h1>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default App;